.my-homepage-title {
  width: 100%;
  color: #303030;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #c7c7c7;
}

.homepage-left-container {
  width: 40%;
  padding-left: 30px;
  padding-right: 30px;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #c7c7c7;
}

.logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-logo {
  width: 150px;
}

.homepage-card {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  padding-top: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
  /* border-style: solid;
    border-width: 1px;
    border-color: #dbdbdb; */
  box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 1.4px;
}

.homepage-card-title {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #303030;
  padding-top: 3px;
  padding-bottom: 10px;
}

.homepage-card-list-element {
  padding-left: 10px;
  font-size: 14px;
  color: #303030;
  cursor: pointer;
}

.homepage-card-list-element:hover {
  background-color: #dedede;
}

.applications-container {
  width: 90%;
  margin-top: 35px;
  border-style: solid;
  border-width: 1px;
  border-color: #dbdbdb;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.my-applications-title {
  width: 90%;
  color: #303030;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #c7c7c7;
}

.app-card-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 30px;
}

.app-card {
  width: 35%;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #29b5e8;
  color: white;
  border-radius: 5px;
  padding-top: 40px;
  padding-bottom: 40px;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.app-card:hover {
  transform: scale(1.03);
  color: white;
}
