body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul.breadcrumb {
  padding: 10px 16px;
  padding-bottom: 0px;
  list-style: none;
  background-color: #fff;
  margin-left: 14px;
  margin-bottom: 0px;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 14px;
  font-weight: 550;
}

ul.breadcrumb li a {
  color: #303030;
  margin: 0px;
  text-decoration: none;
}

ul.breadcrumb li a:hover {
  text-decoration: underline;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding-left: 5px;
  padding-right: 2px;
  color: '#303030';
  content: '/\00a0';
}

button:focus {
  outline: 0 !important;
}

.userinfo {
  margin-left: auto;
  color: white;
  align-self: center;
  gap: 1rem;
  padding-right: 1rem;
}

.userinfo>img {
  height: 2rem;
  border-radius: 50%;
}