.model-details-content {
  width: '100%';
  padding: 0 18px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0.25rem;
}

.list-element {
  padding: 10px;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 14px;
  color: #303030;
}
