/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
  'info': tomato,
  'danger': teal,
  'primary': rgb(85, 206, 250),
  'secondary': #1e2b33,
  'success': rgb(105, 211, 105)
);

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
