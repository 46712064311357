.expand-pipelines-button {
  background-color: #f5f5f5;
  color: #303030;
  cursor: pointer;
  padding: 16px;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  border-radius: 0.25rem;
  border: none;
  /*border-color: #dbdbdb;*/
}

.expand-pipelines-content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0.25rem;
}

.list-element {
  padding: 10px;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 14px;
  color: #303030;
}

.list-container:hover {
  background-color: #f5f5f5;
}
