.model-list-item {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  border-color: #fff;
}

.model-list-item:hover {
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  border-color: #303030;
}

.model-list-item-active {
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  border-color: #303030;
  background-color: #f5f5f5;
}

.timing-details-button {
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  border-color: #dbdbdb;
  background-color: #fff;
}

.timing-details-button:hover {
  background-color: #f0f0f0;
}
